<template>
  <div>
    <el-dialog
      title="审核确认"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <el-form ref="form" :model="form" label-width="110px" :rules="rules">
        <el-form-item label="审核：" prop="reviewStatus">
          <el-select v-model="form.reviewStatus" placeholder="请选择">
            <el-option label="通过" value="1"></el-option>
            <el-option label="驳回" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.reviewStatus == 2" label="驳回说明：" prop="reviewRemark">
          <el-input
            v-model="form.reviewRemark"
            type="textarea"
            rows="6"
            maxlength="256"
            placeholder="请填写驳回说明"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { examine } from '@/api/certificate'
import to from 'await-to'
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        userCertSignRecordId: '',
      },
      rules: {},
    }
  },
  created() {},
  methods: {
    show() {
      this.dialogVisible = true
      this.form.userCertSignRecordId = this.$route.query.userCertSignRecordId
    },
    async submit() {
      const [, err] = await to(examine(this.form))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '审核成功' })
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped></style>
