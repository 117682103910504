<template>
  <div class="add_credentials">
    <el-card v-if="form.signStatusValue == '未通过'" class="headerCard">
      <div class="fail">
        <img src="../../../../assets/certificateManage/no_icon.png" alt="" />
        <div class="failText">资料审核未通过，驳回原因:{{ form.reviewRemark }}</div>
      </div>
    </el-card>
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ uploadData ? '上传资料' : '报名资料' }}</span>
        <div v-if="!uploadData" class="btns" @click="handleData">上传资料</div>
        <div v-if="!uploadData" class="btns" @click="examine">审核</div>
      </div>
      <el-form
        ref="form"
        :disabled="!uploadData == true"
        :model="form"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="人员属性">
          <el-radio-group v-model="form.userBaseInfo.personnelAttributes">
            <el-radio :label="1">社会人员</el-radio>
            <el-radio :label="2">在校学生</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="真实姓名" prop="userBaseInfo.signUserName">
          <el-input
            v-model="form.userBaseInfo.signUserName"
            placeholder="请输入真实姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="userBaseInfo.idCard">
          <el-input
            v-model="form.userBaseInfo.idCard"
            placeholder="请输入身份证号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="证件照片" prop="pic">
          <div class="cardWrap">
            <div class="card">
              <upload-image
                v-model="form.userBaseInfo.idCardFrontImage"
                :upload-img="$refs.pic"
                :pic-type="'.jpg,.png,.jpeg'"
              ></upload-image>
              <div class="cardText">正面</div>
            </div>
            <div class="card">
              <upload-image
                v-model="form.userBaseInfo.idCardBackImage"
                :upload-img="$refs.pic"
                :pic-type="'.jpg,.png,.jpeg'"
              ></upload-image>
              <div class="cardText">背面</div>
            </div>
          </div>
          <p class="explain">
            大小5MB，支持.jpg、.png、.jpeg
          </p>
        </el-form-item>
        <el-form-item
          ref="registerImage"
          class="register"
          label="登记照"
          prop="userBaseInfo.registerImage"
        >
          <upload-image
            v-model="form.userBaseInfo.registerImage"
            :upload-img="$refs.registerImage"
            :width-size="widthSize"
            :height-size="heightSize"
            :is-lt-pic="'20kb-40kb'"
            :pic-type="'.JPG,.JPEG'"
          ></upload-image>
          <p class="explain">
            1寸彩色白底免冠照片，格式为JPG或JPEG，大小在20kb-40kb，最多可上传1张
          </p>
        </el-form-item>
        <el-form-item
          v-if="form.userBaseInfo.personnelAttributes == 1"
          ref="diplomaImage"
          label="毕业证书照片"
          prop="userBaseInfo.diplomaImage"
          class="graduation"
        >
          <upload-image
            v-model="form.userBaseInfo.diplomaImage"
            :upload-img="$refs.diplomaImage"
            :pic-type="'.png,.jpg,.jpeg'"
          ></upload-image>
          <p class="explain">
            大小5MB，支持.jpg、.png、.jpeg，最多可上传1张
          </p>
        </el-form-item>
        <el-form-item
          v-if="form.userBaseInfo.personnelAttributes == 2"
          ref="studentIdCardImage"
          label="学生证照片"
          prop="userBaseInfo.studentIdCardImage"
          class="graduation"
        >
          <upload-image
            v-model="form.userBaseInfo.studentIdCardImage"
            :upload-img="$refs.studentIdCardImage"
            :pic-type="'.png,.jpg,.jpeg'"
          ></upload-image>
          <p class="explain">
            大小5MB，支持.jpg、.png、.jpeg，最多可上传1张
          </p>
        </el-form-item>
        <el-form-item label="最高学历:" prop="userBaseInfo.educationLevel">
          <el-select v-model="form.userBaseInfo.educationLevel" placeholder="请选择您的最高学历">
            <el-option label="初中" :value="1"></el-option>
            <el-option label="高中" :value="2"></el-option>
            <el-option label="大专" :value="3"></el-option>
            <el-option label="本科" :value="4"></el-option>
            <el-option label="硕士" :value="5"></el-option>
            <el-option label="博士" :value="6"></el-option>
            <el-option label="博士后" :value="7"></el-option>
            <el-option label="未知" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="毕业专业" prop="userBaseInfo.major">
          <el-input
            v-model="form.userBaseInfo.major"
            placeholder="请输入毕业证上描述的专业"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="申请表:" prop="userBaseInfo.applicationList">
          <upload-file
            v-model="form.userBaseInfo.applicationList"
            :file-type="'.docx,.doc'"
            :limit="1"
            :disabled="!uploadData ? true : false"
          ></upload-file>
          <div class="textWrap">
            <p v-if="form.applyForm && form.applyForm !== null" class="upload" @click="downLoad">
              下载申请表模板
            </p>
            <p class="explain">单个文件大小5MB，支持扩展名.docx .doc, 仅支持上传一个文件</p>
          </div>
        </el-form-item>
        <el-form-item ref="tinymceRules" label="报名材料说明:" prop="applyMaterial">
          <!-- <tinymce
            ref="editor"
            v-model="form.applyMaterial"
            :width="750"
            :height="300"
            disabled
          ></tinymce> -->
          <div class="tinymce" v-html="form.applyMaterial"></div>
        </el-form-item>
        <el-form-item ref="enclosures" label="附件:" prop="enclosures" class="Multiple">
          <upload-file
            v-model="form.enclosures"
            :file-type="'.png,.pdf,.jpg,.jpeg'"
            :is-lt-file="'10M'"
            :limit="10"
            :disabled="!uploadData ? true : false"
          ></upload-file>
          <p class="explain">每个文件限制10MB内，支持.png.pdf.jpg.jpeg等，最多可上传10个</p>
        </el-form-item>
        <div class="space">
          <div class="title">补充收件地址</div>
        </div>
        <el-form-item label="收件人姓名" prop="address.contactName">
          <el-input
            v-model="form.address.contactName"
            placeholder="请输入收件人姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="收件人手机号" prop="address.mobile">
          <el-input
            v-model="form.address.mobile"
            placeholder="请输入收件人手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="邮寄地址" prop="address.addressCode">
          <el-cascader
            ref="myCascader"
            v-model="form.address.addressCode"
            :options="totalCity"
            :props="{
              value: 'value',
            }"
            placeholder="请选择邮寄地址"
            @change="handleAddressChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address.address">
          <el-input
            v-model="form.address.address"
            type="textarea"
            style="width:350px"
            rows="6"
            placeholder="请填写详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-if="uploadData" type="primary" :loading="loading" @click="submit('form')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <ExamineDia ref="ExamineDia" />
  </div>
</template>

<script>
import ExamineDia from './ExamineDia.vue'
import UploadImage from '@/components/UploadImage'
import UploadFile from '@/components/UploadFile'
import totalCity from '../../../../assets/address/totalCity'
import { uploadData, dataDetail } from '@/api/certificate'
import to from 'await-to'
export default {
  components: {
    UploadFile,
    UploadImage,
    ExamineDia,
  },
  props: {
    uploadData: {
      type: String,
      default: '',
    },
  },
  data() {
    var checkedCardId = (rule, value, callback) => {
      const IDCardRegex = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/
      if (!value) {
        return callback(new Error('身份证号不能为空'))
      } else if (!IDCardRegex.test(value)) {
        return callback(new Error('身份证格式不正确'))
      } else {
        callback()
      }
    }
    // var checkedPhone = (rule, value, callback) => {
    //   const PhoneRegex = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
    //   if (!value) {
    //     return callback(new Error('手机号不能为空'))
    //   } else if (!PhoneRegex.test(value)) {
    //     return callback(new Error('手机号式不正确'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      widthSize: 295,
      heightSize: 413,
      totalCity,
      form: {
        userBaseInfo: {
          personnelAttributes: 1,
          applicationList: [],
        },
        address: {},
        enclosures: [],
        enclosuresList: [],
        userCertSignRecordId: this.$route.query.userCertSignRecordId,
      },
      loading: false,
      status: '',
      rules: {
        'userBaseInfo.signUserName': [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          // { min: 2, max: 28, message: '请输入2-28字证书名称', trigger: 'blur' },
        ],
        'userBaseInfo.idCard': [{ required: true, validator: checkedCardId, trigger: 'blur' }],
        // 'userBaseInfo.registerImage': [
        //   { required: true, message: '请上传登记照片', trigger: 'change' },
        // ],
        // 'userBaseInfo.diplomaImage': [
        //   { required: true, message: '请上传毕业证书照片', trigger: 'change' },
        // ],
        // 'userBaseInfo.studentIdCardImage': [
        //   { required: true, message: '请上传学生证照片', trigger: 'change' },
        // ],
        // 'userBaseInfo.applicationList': [
        //   { required: true, message: '请上传申请表', trigger: 'change' },
        // ],
        // 'from.applyMaterial': [
        //   // { required: true, message: '请填写申请资料', trigger: 'blur' },
        // ],
        'userBaseInfo.educationLevel': [
          { required: true, message: '请选择您的最高学历', trigger: 'change' },
        ],
        'userBaseInfo.major': [
          { required: true, message: '请输入毕业证上描述的专业', trigger: 'blur' },
          { min: 2, max: 32, message: '请输入2-32字证书名称', trigger: 'blur' },
        ],
        // 'address.contactName': [{ required: true, message: '请输入收件人姓名', trigger: 'blur' }],
        // 'address.mobile': [{ required: true, validator: checkedPhone, trigger: 'blur' }],
        // 'address.addressCode': [{ required: true, message: '请选择城市', trigger: 'change' }],
        // 'address.address': [{ required: true, message: '请填写详细地址', trigger: 'blur' }],
      },
    }
  },
  created() {},
  mounted() {
    this.dataDetailData()
  },
  methods: {
    async submit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // if (!this.form.userBaseInfo.idCardFrontImage && !this.form.userBaseInfo.idCardBackImage) {
          //   this.$message.warning('请上传身份证照片')
          // } else if (!this.form.userBaseInfo.idCardFrontImage) {
          //   this.$message.warning('请上传身份证正面照片')
          // } else if (!this.form.userBaseInfo.idCardBackImage) {
          //   this.$message.warning('请上传身份证背面照片')
          // } else {
          this.form.enclosures = this.form.enclosures.map(v => ({
            ...v,
            fileName: v.name ? v.name : v.fileName,
            docName: v.name ? v.name : v.fileName,
            enclosureUrl: v.url ? v.url : v.enclosureUrl,
          }))
          if (this.form.userBaseInfo.applicationList.length > 0) {
            this.form.userBaseInfo.application = this.form.userBaseInfo.applicationList[0].url
            this.form.userBaseInfo.applicationName = this.form.userBaseInfo.applicationList[0].name
          } else {
            this.form.userBaseInfo.application = ''
            this.form.userBaseInfo.applicationName = ''
          }
          this.loading = true
          const [, err] = await to(uploadData(this.form))
          this.loading = false
          if (err) return this.$message.error(err.msg)
          this.$message.success('上传成功')
          this.$router.push('/certificate/signUp')
          // }
        }
      })
      console.log(this.form)
    },
    async dataDetailData() {
      const [res, err] = await to(
        dataDetail({ userCertSignRecordId: this.$route.query.userCertSignRecordId }),
      )
      if (err) return this.$message.error(err.msg)
      this.form = {
        ...res.data,
      }
      if (this.form.address !== null) {
        this.form.address.addressCode = [
          this.form.address.provinceCode,
          this.form.address.cityCode,
          this.form.address.districtCode,
        ]
      } else {
        this.form.address = {}
      }
      // this.form.enclosuresList = this.form.enclosures
      if (this.form.userBaseInfo !== null) {
        this.form.userBaseInfo.applicationList = [
          {
            docName: this.form.userBaseInfo.applicationName,
            url: this.form.userBaseInfo.application,
          },
        ]
      } else {
        this.form.userBaseInfo = {
          applicationList: [],
          personnelAttributes: 1,
        }
      }

      console.log(this.form)
    },
    examine() {
      this.$refs.ExamineDia.show()
    },
    async downLoad() {
      let src = this.form.applyForm
      const response = await fetch(src)
      const blob = await response.blob()
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '申请表'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleData() {
      this.$router.push({
        path: '/certificate/uploadData',
        query: {
          userCertSignRecordId: this.$route.query.userCertSignRecordId,
        },
      })
    },
    handleAddressChange(value) {
      this.form.address.provinceCode = value[0]
      this.form.address.cityCode = value[1]
      this.form.address.districtCode = value[2]
      for (var i = 0; i < this.totalCity.length; i++) {
        var item = totalCity[i]
        if (item.value == value[0]) {
          this.form.address.provinceName = item.label
        }
        if (item.children.length > 0) {
          for (var k = 0; k < item.children.length; k++) {
            var twoItem = item.children[k]
            if (twoItem.value == value[1]) {
              this.form.address.cityName = twoItem.label
            }
            if (twoItem.children.length > 0) {
              for (var j = 0; j < twoItem.children.length; j++) {
                var threeItem = twoItem.children[j]
                if (threeItem.value == value[2]) {
                  this.form.address.districtName = threeItem.label
                }
              }
            }
          }
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.add_credentials {
  .el-form-item {
    margin-bottom: 30px !important;
    padding-left: 20px;
    &:last-child {
      margin-top: 50px;
    }
  }
  .clearfix {
    display: flex;
    align-items: center;
    .btns {
      width: 80px;
      line-height: 30px;
      font-size: 14px;
      margin-left: 50px;
      background-color: #ff7b33;
      color: #fff;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .cardWrap {
    display: flex;
    .card {
      &:last-child {
        margin-left: 30px;
      }
      ::v-deep .uploader {
        height: 80px;
        .el-upload {
          width: 120px;
          height: 80px;
        }
      }
      .cardText {
        color: #999999;
        font-size: 14px;
        text-align: center;
      }
    }
  }
  .register,
  .graduation {
    ::v-deep .uploader {
      height: 90px;
      .el-upload {
        width: 120px;
        height: 80px;
      }
    }
  }
  .textWrap {
    display: flex;
    .upload {
      font-size: 12px;
      color: #ff7b33;
      margin-right: 12px;
      cursor: pointer;
    }
  }
  .Multiple {
    ::v-deep .el-upload--picture-card {
      width: 90px !important;
      height: 90px !important;
      .explain {
        height: 90px;
      }
    }
  }
  .explain {
    font-size: 12px;
    color: #999999;
  }
  ::v-deep .el-card__header {
    background: #fcfafa !important;
  }
  ::v-deep .el-card__body {
    padding: 0 !important;
  }
  .space {
    background: #fcfafa;
    line-height: 67px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ebeef5;
    .title {
      padding-left: 27px;
      font-size: 18px;
      color: #333;
    }
  }
  .headerCard {
    ::v-deep .el-card__body {
      // height: 102px !important;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 20px !important;
    }
    .fail {
      width: 95%;
      // height: 62px;
      display: flex;
      border: 1px solid #f67878;
      border-radius: 4px;
      padding: 14px 0 20px 20px;
      img {
        width: 14px;
        height: 14px;
        margin-top: 4px;
      }
      .failText {
        width: 694px;
        font-size: 14px;
        color: #f67878;
        margin-left: 10px;
      }
    }
  }
}
.tinymce {
  width: 750px;
  height: 300px;
  border: 1px solid #ededed;
  overflow-y: auto;
}
</style>
