<!--
 * @Description: 上传文件
 * @Author: xiawenlong
 * @Date: 2020-12-19 14:23:46
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-06-22 17:34:52
-->
<template>
  <div class="upload-file">
    <el-upload
      class="uploader-file"
      action=""
      :file-list="fileList"
      :disabled="disabled"
      drag
      multiple
      :limit="limit ? limit : 1000"
      :http-request="uploadRequest"
      :on-remove="handleRemove"
      :on-preview="handlePreview"
      :before-upload="beforeAvatarUpload"
      :class="{ disabledActive: disabled }"
      :accept="fileType ? filterAccept(fileType) : '.rar,.zip,.doc,.docx,.pdf,.jpg,.jpeg'"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <!-- <p v-if="status == 0" class="tip" style="line-height:6px">
        支持扩展名：.rar .zip .doc .docx .pdf .jpg
      </p>
      <p v-else class="tip" style="line-height:6px">仅支持扩展名：.rar .zip</p> -->
      <p class="tip" style="line-height:6px">
        支持扩展名：{{ fileType ? filterAccept(fileType) : '.rar,.zip,.doc,.docx,.pdf,.jpg,.jpeg' }}
      </p>
    </el-upload>
  </div>
</template>
<script>
import { getFileUpload } from '@/api/college'
import to from 'await-to'
export default {
  name: 'UploadImage',
  model: {
    prop: 'value',
    event: 'setModelVal',
  },
  props: {
    value: {
      type: Array,
      default: () => {},
    },
    limit: {
      type: Number,
      default: 0,
    },
    fileType: {
      type: String,
      default: '',
    },
    isLtFile: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      fileList: [],
    }
  },
  watch: {
    value(val) {
      if (val) {
        if (!val[0].uid) {
          this.fileList = val.map(item => ({
            ...item,
            name: item.docName ? item.docName : item.fileName,
          }))
        }
      } else {
        this.fileList = []
      }
    },
  },
  methods: {
    async uploadRequest(file) {
      const params = new FormData()
      params.append('fileTypeCode', 'F002')
      params.append('file', file.file)
      params.append('fileName', file.file.name)
      const [res, err] = await to(getFileUpload(params))
      if (err) {
        this.fileList = []
        return this.$message.error(err.msg)
      }
      this.$emit('setModelVal', [
        ...this.fileList,
        {
          name: file.file.name,
          docName: file.file.name,
          url: res.data,
        },
      ])
    },
    beforeAvatarUpload(file) {
      let isLtPicSize = null
      if (this.isLtFile) {
        if (this.isLtFile.indexOf('-') !== -1) {
          let starStr = this.isLtFile.substring(0, this.isLtFile.indexOf('-'))
          let lastStr = this.isLtFile.substring(
            this.isLtFile.lastIndexOf('-') + 1,
            this.isLtFile.length,
          )
          console.log(starStr, lastStr)
          var starStrNum = starStr.match(/\d/g).join('')
          var starStrEnglish = starStr.match(/[a-z]/gi).join('')
          var lastStrNum = lastStr.match(/\d/g).join('')
          var lastStrEnglish = starStr.match(/[a-z]/gi).join('')
          console.log(starStrNum, starStrEnglish)
          isLtPicSize =
            this.isKbOrM(file.size, starStrEnglish, starStrNum) < starStrNum ||
            this.isKbOrM(file.size, lastStrEnglish, lastStrNum) > lastStrNum
          if (!isLtPicSize) {
            this.$message.error(`上传图片大小不能小于${starStr}，不能超过 ${lastStr}!`)
          }
        } else {
          var singleStrNum = this.isLtFile.match(/\d/g).join('')
          var singleStrEnglish = this.isLtFile.match(/[a-z]/gi).join('')
          isLtPicSize = this.isKbOrM(file.size, singleStrEnglish, singleStrNum)
          if (!isLtPicSize) {
            this.$message.error(`上传图片大小不能超过${singleStrNum}${singleStrEnglish}`)
          }
        }
        return isLtPicSize
      } else {
        const isLt5M = file.size / 1024 / 1024 < 5
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!')
        }
        return isLt5M
      }
    },
    filterAccept(val) {
      // if (val == 0) {
      //   return '.rar,.zip,.doc,.docx,.pdf,.jpg,.jpeg'
      // } else if (val == 1) {
      //   return '.rar,.zip'
      // } else if (val == 2) {
      //   return '.docx,.doc'
      // } else if (val == 3) {
      //   return '.pdf'
      // }
      return val
    },
    async handlePreview(file) {
      const response = await fetch(file.url || file.enclosureUrl)
      const blob = await response.blob()
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = file.docName || file.fileName
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      this.$emit('setModelVal', this.fileList)
    },
    isKbOrM(size, type, num) {
      if (type == 'kb') {
        //kb-kb
        if (this.isLtFile.indexOf('-')) {
          return size / 1024 / num
        } else {
          return size / 1024 / num < num
        }
      } else {
        //M-M
        if (this.isLtFile.indexOf('-')) {
          return size / 1024 / 1024 / num
        } else {
          return size / 1024 / 1024 / num < num
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-file {
  ::v-deep.el-upload-list {
    width: 360px;
  }
}
.disabledActive {
  ::v-deep .el-upload {
    display: none;
  }
}
</style>
