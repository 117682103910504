import { render, staticRenderFns } from "./UploadFile.vue?vue&type=template&id=040a4324&scoped=true&"
import script from "./UploadFile.vue?vue&type=script&lang=js&"
export * from "./UploadFile.vue?vue&type=script&lang=js&"
import style0 from "./UploadFile.vue?vue&type=style&index=0&id=040a4324&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040a4324",
  null
  
)

export default component.exports